#root {
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

html {
  height: -webkit-fill-available;
}
